import { formatTimestamp } from "./editsub";
import type { Node, FormatOptions } from "../types/node";
import { VTTCue } from "captions";

export class Formatter {
  private options: FormatOptions;
  private hasReceivedHeader: boolean;
  private index: number;

  constructor(options: FormatOptions) {
    this.options = options;
    this.hasReceivedHeader = false;
    this.index = 1;
  }

  public format(node: Node): string {
    let buffer = "";

    if (node.type === "header" && ["vtt"].includes(this.options.format)) {
      this.hasReceivedHeader = true;
      buffer += `${node.data}\n\n`;
    }

    if (node.type === "cue") {
      if (!this.hasReceivedHeader && ["vtt"].includes(this.options.format)) {
        this.hasReceivedHeader = true;
        buffer += "WEBVTT\n\n";
      }

      buffer += this.formatData(
        node.data as VTTCue,
        this.index++,
        this.options
      );
    }

    return buffer;
  }

  private formatData(data: VTTCue, index: number, options: FormatOptions) {
    const text = options.isTranslated
      ? options.merged
        ? data.text + "\n" + (data as VTTCue).translation
        : (data as VTTCue).translation
      : data.text;

    const formattedText = text + "\n\n";
    switch (this.options.format) {
      case "txt":
        return formattedText;
      case "sbv":
        return `${formatTimestamp(
          data.startTime * 1000,
          options
        )},${formatTimestamp(data.endTime * 1000, options)}\n${formattedText}`;

      case "vtt":
      case "srt":
        return [
          `${index > 1 ? "\n" : ""}${index}`,
          `${formatTimestamp(
            data.startTime * 1000,
            options
          )} --> ${formatTimestamp(data.endTime * 1000, options)}${
            options.format === "vtt" && data.settings ? " " + data.settings : ""
          }`,
          text,
          "",
        ].join("\n");
    }
  }
}
