import type { VTTCue } from "captions";
import type { Cue } from "../types/node";

export function createCue(vttCue: VTTCue): Cue {
  const baseCue: Omit<Cue, "new"> = {
    startTime: vttCue.startTime,
    endTime: vttCue.endTime,
    text: vttCue.text,
    region: vttCue.region,
    lineAlign: vttCue.lineAlign,
    positionAlign: vttCue.positionAlign,
    addEventListener: vttCue.addEventListener,
    removeEventListener: vttCue.removeEventListener,
    dispatchEvent: vttCue.dispatchEvent,
    vertical: vttCue.vertical,
    snapToLines: vttCue.snapToLines,
    line: vttCue.line,
    position: vttCue.position,
    size: vttCue.size,
    align: vttCue.align,
    id: vttCue.id,
    pauseOnExit: vttCue.pauseOnExit,
  };

  const handler = {
    get(target: Omit<Cue, "new">, prop: string, receiver: any) {
      if (prop === "new") {
        return (startTime: number, endTime: number, text: string) => {
          return createCue({
            startTime,
            endTime,
            text,
          } as VTTCue);
        };
      }
      return Reflect.get(target, prop, receiver);
    },
  };

  return new Proxy(baseCue, handler) as Cue;
}
