export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();

  if (process.env.NODE_ENV !== "development") {
    useHead({
      //   script: [
      //     {
      //       src: "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5684609129325792",
      //       async: true,
      //       crossorigin: "anonymous",
      //     },
      //   ],
    });
  }
});
