const SECRET_KEY = "editsub.";

export function encrypt(text: string): string {
  if (!text) return "";

  const textToChars = (text: string) => new TextEncoder().encode(text);
  const byteHex = (n: number) => ("0" + Number(n).toString(16)).substr(-2);
  const applySaltToChar = (code: number) =>
    textToChars(SECRET_KEY).reduce((a, b) => a ^ b, code);

  return Array.from(textToChars(text))
    .map(applySaltToChar)
    .map(byteHex)
    .join("");
}

export function decrypt(encoded: string): string {
  if (!encoded) return "";

  const textToChars = (text: string) => new TextEncoder().encode(text);
  const applySaltToChar = (code: number) =>
    textToChars(SECRET_KEY).reduce((a, b) => a ^ b, code);

  const decodedBytes =
    encoded
      .match(/.{1,2}/g)
      ?.map((hex) => parseInt(hex, 16))
      .map(applySaltToChar) || [];

  return new TextDecoder().decode(new Uint8Array(decodedBytes));
}
