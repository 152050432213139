import videojs from "video.js";
import WaveSurfer from "wavesurfer.js";

const Plugin = videojs.getPlugin("plugin");

class WavesurferPlugin extends Plugin {
  private wavesurfer: WaveSurfer | null = null;
  private waveformContainer: HTMLElement | null = null;
  private options: any;

  constructor(player: any, options: any) {
    super(player, options);
    this.options = options || {};
    this.player.ready(() => {
      this.initWavesurfer();
    });
  }

  initWavesurfer() {
    if (this.options.container) {
      this.waveformContainer = this.options.container;
    } else {
      this.waveformContainer = document.createElement("div");
      this.waveformContainer.className = "vjs-wavesurfer-container";
      this.player.el().appendChild(this.waveformContainer);
    }

    const wavesurferOptions = {
      container: this.waveformContainer,
      waveColor: this.options.waveColor || "#ddd",
      progressColor: this.options.progressColor || "#0074D9",
      cursorColor: this.options.cursorColor || "#ddd",
      hideScrollbar: true,
      interact: true,
      responsive: true,
      fillParent: true,
      minPxPerSec: this.options.minPxPerSec || 10,
      plugins: this.options.plugins || [],
      backend: "MediaElement",
      mediaControls: false,
      autoplay: false,
    };

    this.wavesurfer = WaveSurfer.create(wavesurferOptions);
    this.wavesurfer.setMuted(true);
    const audioSource = this.player.currentSource();
    if (audioSource && audioSource.src) {
      this.wavesurfer.load(audioSource.src);
    }

    this.player.trigger("wavesurferCreated", this.wavesurfer);

    // add event
    this.wavesurfer.on("ready", () => {
      this.player.trigger("wavesurferReady", this.wavesurfer);
    });
    this.wavesurfer.on("decode", () => {
      this.player.trigger("wavesurferDecode");
    });
    // add event
    this.wavesurfer.on("error", (err) => {
      console.error("WaveSurfer error:", err);
    });

    this.player.on("timeupdate", () => {
      if (this.wavesurfer) {
        this.wavesurfer.seekTo(
          this.player.currentTime() / this.player.duration()
        );
      }
    });

    this.player.on("play", () => {
      if (this.wavesurfer) {
        this.wavesurfer.play();
      }
    });

    this.player.on("pause", () => {
      if (this.wavesurfer) {
        this.wavesurfer.pause();
      }
    });
  }

  dispose() {
    if (this.wavesurfer) {
      this.wavesurfer.destroy();
    }
    if (this.waveformContainer && !this.options.container) {
      this.player.el().removeChild(this.waveformContainer);
    }
    super.dispose();
  }

  // add getter for wavesurfer
  getWaveSurfer(): WaveSurfer | null {
    return this.wavesurfer;
  }
}

// register plugin with videojs
videojs.registerPlugin("wavesurfer", WavesurferPlugin);

// extend type for Videojs
declare module "video.js" {
  interface VideoJsPlayer {
    wavesurfer: () => WavesurferPlugin;
  }
}

export default defineNuxtPlugin(() => {
  // plugin already registered, no need to do anything here
});
