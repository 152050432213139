import { default as authdjl0VO0QO1Meta } from "/opt/buildhome/repo/pages/auth.vue?macro=true";
import { default as conversation4iOzM3fTdtMeta } from "/opt/buildhome/repo/pages/conversation.vue?macro=true";
import { default as convertyGz42cAWGLMeta } from "/opt/buildhome/repo/pages/convert.vue?macro=true";
import { default as dashboard6cxkiptQlsMeta } from "/opt/buildhome/repo/pages/dashboard.vue?macro=true";
import { default as downsubbMBCpLAajKMeta } from "/opt/buildhome/repo/pages/downsub.vue?macro=true";
import { default as _91id_93aCaPOHi3NuMeta } from "/opt/buildhome/repo/pages/edit/[id].vue?macro=true";
import { default as indexfdg4BHm5yYMeta } from "/opt/buildhome/repo/pages/edit/index.vue?macro=true";
import { default as faq6z6qNnXVtzMeta } from "/opt/buildhome/repo/pages/faq.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as privacy_45policypnUDKF1OY5Meta } from "/opt/buildhome/repo/pages/privacy-policy.vue?macro=true";
import { default as settings4cDYwTsA31Meta } from "/opt/buildhome/repo/pages/settings.vue?macro=true";
import { default as term_45of_45useM9uZcsSpXvMeta } from "/opt/buildhome/repo/pages/term-of-use.vue?macro=true";
import { default as translateApi3x1cvtwMeta } from "/opt/buildhome/repo/pages/translate.vue?macro=true";
import { default as component_45stubGZr1UkTkg3Meta } from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubGZr1UkTkg3 } from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "auth",
    path: "/auth",
    meta: authdjl0VO0QO1Meta || {},
    component: () => import("/opt/buildhome/repo/pages/auth.vue")
  },
  {
    name: "conversation",
    path: "/conversation",
    component: () => import("/opt/buildhome/repo/pages/conversation.vue")
  },
  {
    name: "convert",
    path: "/convert",
    meta: convertyGz42cAWGLMeta || {},
    component: () => import("/opt/buildhome/repo/pages/convert.vue")
  },
  {
    name: "dashboard",
    path: "/dashboard",
    component: () => import("/opt/buildhome/repo/pages/dashboard.vue")
  },
  {
    name: "downsub",
    path: "/downsub",
    meta: downsubbMBCpLAajKMeta || {},
    component: () => import("/opt/buildhome/repo/pages/downsub.vue")
  },
  {
    name: "edit-id",
    path: "/edit/:id()",
    meta: _91id_93aCaPOHi3NuMeta || {},
    component: () => import("/opt/buildhome/repo/pages/edit/[id].vue")
  },
  {
    name: "edit",
    path: "/edit",
    meta: indexfdg4BHm5yYMeta || {},
    component: () => import("/opt/buildhome/repo/pages/edit/index.vue")
  },
  {
    name: "faq",
    path: "/faq",
    meta: faq6z6qNnXVtzMeta || {},
    component: () => import("/opt/buildhome/repo/pages/faq.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexniDiYCWjuTMeta || {},
    component: () => import("/opt/buildhome/repo/pages/index.vue")
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    meta: privacy_45policypnUDKF1OY5Meta || {},
    component: () => import("/opt/buildhome/repo/pages/privacy-policy.vue")
  },
  {
    name: "settings",
    path: "/settings",
    component: () => import("/opt/buildhome/repo/pages/settings.vue")
  },
  {
    name: "term-of-use",
    path: "/term-of-use",
    meta: term_45of_45useM9uZcsSpXvMeta || {},
    component: () => import("/opt/buildhome/repo/pages/term-of-use.vue")
  },
  {
    name: "translate",
    path: "/translate",
    meta: translateApi3x1cvtwMeta || {},
    component: () => import("/opt/buildhome/repo/pages/translate.vue")
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/sitemap.xml",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/index-sitemap.xml",
    component: component_45stubGZr1UkTkg3
  }
]