import { Formatter } from "../utils/Formatter";
import {
  formatTimestamp,
  parseTimestamp,
  parseSync,
  validateTimestamp,
} from "../utils/editsub";
import {
  type NodeList,
  type Format,
  type FormatOptions,
  type NodeCue,
  type Cue,
} from "../types/node";
import { parseText } from "captions";
import type { VTTCue, CaptionsFileFormat } from "captions";
import { createCue } from "../utils/cue";

export default defineNuxtPlugin((nuxtApp) => {
  const i18n = nuxtApp.$i18n;

  const editsub = {
    formatTimestamp: (timestamp: number, options?: FormatOptions): string => {
      return formatTimestamp(timestamp, options);
    },

    validateTimestamp: (timestamp: string): boolean => {
      return validateTimestamp(timestamp);
    },

    parseTimestamp: (timestamp: string): number => {
      return parseTimestamp(timestamp);
    },

    parseSync: async (
      subtitle: string,
      extension: string = "srt"
    ): Promise<NodeList> => {
      let subtitles: NodeList = [];

      const { metadata, regions, cues, errors } = await parseText(subtitle, {
        strict: false,
        type: extension as CaptionsFileFormat,
      });

      // console.log(metadata, regions, cues, errors);
      if (errors.length > 0) {
        throw new Error(errors.join("\n"));
      }
      cues.forEach((vttCue: VTTCue) => {
        const cue = createCue(vttCue);
        const node: NodeCue = {
          type: "cue",
          data: cue,
        };
        subtitles.push(node);
      });
      return subtitles;
    },

    stringifySync: (list: NodeList, options: FormatOptions): string => {
      const formatter = new Formatter(options);

      return list.reduce((buffer, node) => {
        return buffer + formatter.format(node);
      }, "");
    },

    sanitizeFilename: (input: string): string => {
      return input
        .replace(/[^a-z0-9]/gi, "_") // Replace non-alphanumeric characters with underscore
        .replace(/_{2,}/g, "_") // Replace multiple underscores with a single underscore
        .replace(/^_+|_+$/g, "") // Remove leading and trailing underscores
        .toLowerCase(); // Convert all characters to lowercase
    },

    downloadSubtitles: (
      subtitles: NodeList,
      title: string,
      format: Format,
      isTranslated: boolean,
      merged: boolean
    ) => {
      const content = editsub.stringifySync(subtitles, {
        format,
        isTranslated,
        merged,
        isShortened: false,
      });
      const blob = new Blob([content], { type: "text/plain" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `[Editsub.com]${
        title ? editsub.sanitizeFilename(title) : "subtitle"
      }.${format}`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    },

    translateSubtitles: async (
      subtitles: NodeList,
      targetLanguage: string
    ): Promise<NodeList> => {
      try {
        const MAX_CHARS = 4500;

        const nodesToTranslate = subtitles.filter(
          (subtitle) =>
            subtitle.type === "cue" &&
            subtitle.data &&
            typeof subtitle.data === "object" &&
            "text" in subtitle.data
        );

        let batches: { texts: string[]; startIndex: number }[] = [];
        let currentBatch: string[] = [];
        let currentBatchLength = 0;
        let startIndex = 0;

        for (const subtitle of nodesToTranslate) {
          const subtitleText = (subtitle.data as { text: string }).text;
          if (
            currentBatchLength + subtitleText.length > MAX_CHARS &&
            currentBatch.length > 0
          ) {
            batches.push({ texts: currentBatch, startIndex });
            startIndex += currentBatch.length;
            currentBatch = [];
            currentBatchLength = 0;
          }
          currentBatch.push(subtitleText);
          currentBatchLength += subtitleText.length;
        }

        if (currentBatch.length > 0) {
          batches.push({ texts: currentBatch, startIndex });
        }

        const results = await Promise.all(
          batches.map((batch) => editsub.translateBatch(batch, targetLanguage))
        );

        results.sort((a, b) => a.startIndex - b.startIndex);

        let translationIndex = 0;
        for (const subtitle of nodesToTranslate) {
          if (subtitle.data && typeof subtitle.data === "object") {
            const batchResult = results.find(
              (r) =>
                r.startIndex <= translationIndex &&
                translationIndex < r.startIndex + r.translations.length
            );
            if (batchResult) {
              const indexInBatch = translationIndex - batchResult.startIndex;
              (subtitle.data as { translation?: string }).translation =
                batchResult.translations[indexInBatch] || "";
            }
            translationIndex++;
          }
        }
        return subtitles;
      } catch (error) {
        console.error("Error translating subtitles:", error);
        throw new Error(i18n.t("errors.unableToTranslate"));
      }
    },

    translateBatch: async (
      batch: { texts: string[]; startIndex: number },
      targetLanguage: string
    ) => {
      try {
        const response = await fetch("/api/translate", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            texts: batch.texts.map((text) => encrypt(text)),
            to: targetLanguage,
          }),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        if (!data.translatedText || data.translatedText.length === 0) {
          throw new Error("Unable to get translated text from API");
        }

        return {
          translations: data.translatedText,
          startIndex: batch.startIndex,
        };
      } catch (error) {
        console.error("Error translating batch:", error);
        throw new Error("Unable to translate batch. Please try again later.");
      }
    },
  };

  return {
    provide: {
      editsub,
    },
  };
});
